import React, { Component, Fragment } from 'react';

import PasswordForgetForm from '../../organisms/PasswordForget/password-forget';
import ResponseHandler from '../../molecules/ResponseHandler/response-handler';
import Logo from '../../../assets/adsight.png';

//Styles
import './password-forget.scss';

class PasswordForget extends Component {
  state = {
    showResponse: false,
  };

  responseHandler = () => {
    this.setState({ showResponse: true });
  };
  deleteMessage = () => {
    this.setState({ showResponse: false });
  };

  render() {
    let response = null;
    if (this.state.showResponse) {
      response = (
        <ResponseHandler
          response={{
            type: 'info',
            title: 'Reset account password',
            message:
              'Further instruction to reset your account password will be emailed to you shortly',
          }}
          deleteMessage={this.deleteMessage}
        />
      );
    }
    return (
      <Fragment>
        {response}
        <div className="gradient-bar" />
        <div className="pass-forget">
          <div className="pass-forget__content">
            <div className="pass-forget__content__inner">
              <div className="pass-forget__content__header">
                <img
                  className="pass-forget__content__header__logo"
                  src={Logo}
                  alt="logo"
                />
              </div>
              <div className="pass-forget__content__pass-forget-form">
                <div className="pass-forget__content__form">
                  <PasswordForgetForm showResponse={this.responseHandler} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PasswordForget;
