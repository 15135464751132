import React, { Component } from 'react';
import { withFirebase } from '../../../utils/Firebase';
import PasswordForgetForm from '../../molecules/PasswordForgetForm/password-forget-form';

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForget extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, buttonClass: 'btn-primary' };
  }

  onSubmit = (event) => {
    this.setState({ buttonClass: 'button is-primary is-loading' });
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.props.firebase.useAnalytics('send_reset_link', { email });
        this.setState({ ...INITIAL_STATE });
        this.props.showResponse();
        this.setState({ buttonClass: 'btn-primary' });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ buttonClass: 'btn-primary' });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;
    const { className } = this.props;

    const isInvalid = email === '';

    return (
      <PasswordForgetForm
        className={className}
        onSubmit={this.onSubmit}
        onChange={this.onChange}
        email={email}
        error={error}
        isInvalid={isInvalid}
        buttonClass={this.state.buttonClass}
      />
    );
  }
}

export default withFirebase(PasswordForget);
