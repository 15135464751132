import React from 'react';
import Input from '../../atoms/Input/input';
import Button from '../../atoms/Button/button';
import { SIGN_IN } from '../../../constants/routes';
import { Link } from 'gatsby';

const PasswordForgetForm = ({
  onSubmit,
  onChange,
  isInvalid,
  error,
  email,
  className,
  buttonClass,
}) => {
  return (
    <form className={className} onSubmit={onSubmit}>
      <Input
        name="email"
        value={email}
        onChange={onChange}
        labelName="Email Address"
        type="text"
        required
      />
      <br />

      <Button
        className={buttonClass}
        disabled={isInvalid}
        type="submit"
        text="Send Reset Link"
      />
      <br />
      <p>
        Already have an account? <Link to={SIGN_IN}>Sign In.</Link>
      </p>
      <br />
      {error && <p>{error.message}</p>}
    </form>
  );
};

export default PasswordForgetForm;
